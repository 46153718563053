import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Circle, GoogleMap, InfoWindow, Marker, MarkerClusterer, useLoadScript} from '@react-google-maps/api';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep'

import { getAllPropertiesForMap } from '../api/client';
import Checkers from '../helpers/class/Checkers';
import * as DataFetchHelper from '../helpers/DataFetchHelper';

import CustomMarker from './GoogleMaps/CustomMarker';
import Gallery from './templates/properties/panel/Gallery';
import PanelBody from './templates/properties/panel/PanelBody';
import {fetchBuilderSettingByKey, randomCoordinateFactor} from "../helpers/MethodHelper";
import {
  fetchPropertiesAction,
  setApplyParams,
  setLoadingProperties,
  setOffset,
  updateMapBountries, updateMapTopLeft, updateSearchWithMap
} from "../redux/actions";
import StickyBox from "react-sticky-box";
import MarkerWrapper from "./GoogleMaps/MarkerWrapper";

interface GoogleMapContentProps {
  googleMapApiKey?: string
  showMarkerCluster?: boolean
  center?: any
  mouseMapClick?: any
  app?: any
  updateInfoWindowState?: any
  changeMarkerOnClick?: any
  openInfoWindow?: any
  infoWindowState?: any
  setInfoWindowState?: any
  setMouseMapClick?: any
  map_pois?: any
  secondState?: any
  pixelOffsetX?: any
  pixelOffsetY?: any
  designMode?: any
  propertiesPois?: any
  inViewport?: boolean
  enableMapClustering?: string
  randomizePin?: number
  isEstate?: boolean
  hidePin?: boolean
}

export default function GoogleMapContent ( props : GoogleMapContentProps ) {

  const isEstate = props.isEstate != undefined ? props.isEstate : false

  const hidePin = props.hasOwnProperty('hidePin') ? props.hidePin : false

  const dispatch = useDispatch()

  const propertiesOffset = useSelector(state => state['propertiesParams']['offset']);

  const pageNumber = useSelector(state => state['propertiesParams']['pageNumber']);

  const disableUiControls = Checkers.valueToBoolean(props.app.builder.builder_propertylist_map_disable_controls)

  const builderPropertiesAllProperties = useSelector(
      state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_propertylist_map_all_properties')
  );

  const propertiesParams = useSelector(state => state['propertiesParams']);

  const searchWithMap = propertiesParams['searchWithMap'] != undefined ? propertiesParams['searchWithMap'] : false

  const [propertiesMapPois, setPropertiesMapPois] = useState([]);

  let allPropertiesFetched = false;

  const fetchAllProperties = useCallback((params = propertiesParams, page_number = pageNumber, offset = propertiesOffset) => {
    if (builderPropertiesAllProperties == 1 && allPropertiesFetched) {
      return;
    }

    if (builderPropertiesAllProperties == 0) {
      setPropertiesMapPois(props.propertiesPois);
      return;
    }
    if (!allPropertiesFetched) {
      allPropertiesFetched = true;

      getAllPropertiesForMap(params, builderPropertiesAllProperties).then((response) => {
        setPropertiesMapPois(response);
      })
    }
  }, [propertiesParams]);

  useEffect(() => {
    fetchAllProperties();
  }, [props.propertiesPois]);

  const googleMapApiKey = props.googleMapApiKey;
  const forProperties = props.hasOwnProperty('propertiesPois') && Checkers.isValidArray(propertiesMapPois);

  const [map, setMap] = React.useState(null);
  const [isMounted, setIsMounted] = useState(false);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new google.maps.LatLngBounds();
    if (Checkers.objChildExists(props, 'propertiesPois')) {
      propertiesMapPois.map((marker) => {
        if (Checkers.isValidLatitude(marker.lat) && Checkers.isValidLongitude(marker.lng)) {
          bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
        }
      });
      map.fitBounds(bounds);
    } else if (Checkers.isValidLatitude(props.center.lat) && Checkers.isValidLongitude(props.center.lng)) {
      map.setCenter(props.center);
    }
    map.setZoom(13);
    setMap(map);
  }, [props]);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, []);

  const {isLoaded} = useLoadScript({
    googleMapsApiKey: googleMapApiKey
  });

  const [initMapBounds, setInitMapBounds] = useState(false)

  useEffect(function () {
    if (propertiesMapPois!=undefined && propertiesMapPois.length == 0) {
      return
    }

    if (initMapBounds && searchWithMap) {
      return
    }

    setIsMounted(true);
    if (map && !props.mouseMapClick && Checkers.objChildExists(props, 'propertiesPois')) {
      const bounds = new google.maps.LatLngBounds();
      propertiesMapPois.map((marker) => {
        if (Checkers.isValidLatitude(marker.lat) && Checkers.isValidLongitude(marker.lng)) {
          bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
        }
      });
      map.fitBounds(bounds);
    }

    setInitMapBounds(true)

  }, [isMounted, isLoaded, map, propertiesMapPois]);

  const propertyMarkerClass = () => {
    let result = 'd-flex justify-content-center align-items-center btn-map btn-design-color rounded-circle cursor-pointer';
    result += props.app?.designMode ? '#ffffff' : '#000000';

    return result
  }

  const applyMapFiltering = (params) => {
    if (!searchWithMap) {
      return
    }

    // dispatch( setOffset( 0 ) )
    dispatch<any>(fetchPropertiesAction(params))
    fetchAllProperties(params)
    // dispatch( setApplyParams( 1 ) )
    // dispatch( setLoadingProperties( true ) );
  }

  // const mapBounds = map.getBounds()

  const mapHandler = (params = propertiesParams) => {
    if (map != null && map.getBounds() != null && searchWithMap) {
      let latTop = map.getBounds().Ua.hi
      let latBottom = map.getBounds().Ua.lo
      let lngRight = map.getBounds().Ha.hi
      let lngLeft = map.getBounds().Ha.lo

      params.latTop = latTop
      params.latBottom = latBottom
      params.lngRight = lngRight
      params.lngLeft = lngLeft

      dispatch(updateMapBountries(latTop, latBottom, lngLeft, lngRight))

      applyMapFiltering(params)

    }
  }

  const searchWithMapHandler = () => {
    dispatch(updateSearchWithMap(!searchWithMap))
    applyMapFiltering(propertiesParams)
  }

  const mapRef = useRef();
  return isLoaded && (
      <Fragment>
        <GoogleMap
            ref={mapRef}
            onLoad={onLoad}
            onUnmount={onUnmount}
            clickableIcons={!forProperties}
            mapContainerStyle={{flexGrow: "1", height: "100%"}}
            onDragEnd={() => mapHandler()}
            onZoomChanged={mapHandler}
            options={{
              disableDefaultUI: disableUiControls ? true : false,
              zoomControl: disableUiControls ? false : true,
              scrollwheel: disableUiControls ? false : true,
              gestureHandling: disableUiControls ? 'none' : null,
              styles: [{
                featureType: 'poi',
                stylers: [{ visibility: "off" }]
              }]
            }}
        >


          {/*{*/}
          {/*  <>*/}
          {/*    <div*/}
          {/*        style={{display: "flex", alignItems: "flex-end", width: "100%", height: "100%"}}*/}
          {/*    >*/}
          {/*      <StickyBox offsetBottom={20} bottom={true} className={'map-search-activator design-box'}>*/}
          {/*        <>*/}
          {/*          <div className="form-check primary m-b-0">*/}
          {/*            <input type="checkbox" id="checkColorOpt2" checked={searchWithMap}*/}
          {/*                   onChange={searchWithMapHandler}/>*/}
          {/*            <label htmlFor="checkColorOpt2">*/}
          {/*              Search with map*/}
          {/*            </label>*/}
          {/*          </div>*/}
          {/*        </>*/}
          {/*      </StickyBox>*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*}*/}

          {!forProperties &&
              <>
                {
                  !props.hidePin ?
                      <CustomMarker
                          map={map}
                          offsetY={19}
                          offsetX={19}
                          position={props.center}
                          marker_id={'property'}
                          propertyMarkerClass={propertyMarkerClass()}
                      />
                      :
                      <>
                      </>
                }

                {
                  props.randomizePin == 1 ?
                      <>
                        <Circle
                            center={props.center}
                            radius={500}
                            options={{
                              strokeColor: "#ffffff",
                              fillColor: "#ffffff",
                              fillOpacity: 0.15
                            }}
                        />
                      </>
                      :
                      <></>
                }
              </>
          }


          {
            props.enableMapClustering == "1" ?
                <MarkerClusterer
                    gridSize={50}
                    title={"Click to zoom"}
                    maxZoom={20}
                    minimumClusterSize={2}
                    enableRetinaIcons={true}
                    // onMouseOut={ () => { return } }
                    // onMouseOver={ () => { return } }
                >
                  {(clusterer) => (
                      <>
                        {
                            forProperties && propertiesMapPois.map((property, key) => {
                              if (Checkers.isValidLatitude(property.lat) && Checkers.isValidLongitude(property.lng)) {
                                const position = {
                                  lat: parseFloat(property.lat),
                                  lng: parseFloat(property.lng)
                                }


                                // console.log("clusterer", clusterer, clusterer.getClusters())


                                let markerClass =
                                    props.center.lat == position.lat && props.center.lng == position.lng ? 'd-flex justify-content-center align-items-center btn-map btn-design-color rounded-circle cursor-pointer\ '
                                        : 'd-flex justify-content-center align-items-center btn-map bg-white rounded-circle cursor-pointer\ '
                                ;
                                markerClass += props.designMode && (props.center.lat == position.lat && props.center.lng == position.lng) ? 'text-white' : 'text-black';

                                if (Checkers.isValidLatitude(position.lat) && Checkers.isValidLongitude(position.lng)) {
                                  return (
                                      <MarkerWrapper
                                          key={key}
                                          map={map}
                                          position={position}
                                          clusterer={clusterer}
                                          property={property}
                                          infoWindowState={props.infoWindowState}
                                          updateInfoWindowState={props.updateInfoWindowState}
                                          setInfoWindowState={props.setInfoWindowState}
                                          changeMarkerOnClick={props.changeMarkerOnClick}
                                          // changeMarkerIconOnHover={props.changeMarkerIconOnHover}
                                          openInfoWindow={props.openInfoWindow}
                                          markerClass={markerClass}
                                          pixelOffsetX={props.pixelOffsetX}
                                          pixelOffsetY={props.pixelOffsetY}
                                          secondState={props.secondState}
                                          randomizePin={props.randomizePin}
                                      />
                                  )
                                }
                              }
                            })
                        }
                      </>
                  )}
                </MarkerClusterer>
                :
                <>
                  {forProperties &&
                      propertiesMapPois.map((property, key) => {
                        if (Checkers.isValidLatitude(property.lat) && Checkers.isValidLongitude(property.lng)) {
                          const position = {
                            lat: parseFloat(property.lat),
                            lng: parseFloat(property.lng)
                          }
                          if (Checkers.isValidLatitude(position.lat) && Checkers.isValidLongitude(position.lng)) {
                            let markerClass =
                                props.center.lat == position.lat && props.center.lng == position.lng ? 'd-flex justify-content-center align-items-center btn-map btn-design-color rounded-circle cursor-pointer\ '
                                    : 'd-flex justify-content-center align-items-center btn-map bg-white rounded-circle cursor-pointer\ '
                            ;
                            markerClass += props.designMode && (props.center.lat == position.lat && props.center.lng == position.lng) ? 'text-white' : 'text-black';


                            // console.log("forDebug", "property", property)

                            return (
                                <>
                                  {
                                    props.randomizePin == 1 ?
                                        <>
                                          <Circle
                                              center={position}
                                              radius={220}
                                              options={{
                                                strokeColor: "#ffffff",
                                                fillColor: "#ffffff",
                                                fillOpacity: 0.25
                                              }}
                                          />
                                        </>
                                        :
                                        <>
                                        </>
                                  }
                                  <CustomMarker
                                      key={key}
                                      map={map}
                                      offsetY={19}
                                      offsetX={19}
                                      position={position}
                                      property={property}
                                      marker_id={'property_marker_' + property.property_id}
                                      propertyMarkerClass={markerClass}
                                      onClick={() => {
                                        props.updateInfoWindowState(property.property_id);
                                        props.changeMarkerOnClick(position.lat, position.lng, property.property_id);
                                      }}
                                      disabled={props.infoWindowState === property.property_id}
                                  >
                                    {props.infoWindowState === property.property_id &&
                                        <InfoWindow
                                            zIndex={2}
                                            position={position}
                                            options={{
                                              pixelOffset: new window.google.maps.Size(
                                                  -props.pixelOffsetX,
                                                  -props.pixelOffsetY
                                              )
                                            }}
                                            onDomReady={function () {
                                              const noGalleryWidthDifference = (324 - this.content.children[0].clientWidth);
                                              const noGalleryHeightDifference = (310 - this.content.children[0].clientHeight);

                                              const width = (this.content.children[0].clientWidth + noGalleryWidthDifference) / 2;
                                              const height = this.content.children[0].clientHeight + noGalleryHeightDifference + 20 + 76;

                                              if (width > 0 && height > 20) {
                                                props.openInfoWindow(width, height);
                                              }
                                              const gmEmptyElements = document.querySelectorAll('.gm-style-iw-tc');
                                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                              // @ts-ignore
                                              for (const gmEmptyEl of gmEmptyElements) {
                                                gmEmptyEl.remove();
                                              }
                                            }}
                                        >
                                          <div
                                              id={'info_window_' + property.property_id}
                                              className={
                                                `${!props.secondState ?
                                                    'bg-transparent border-radius-12 position-absolute mb-5 z-index-5'
                                                    : 'bg-white border-radius-12 position-absolute mb-5 z-index-5'}`
                                              }
                                              style={{overflow: "hidden", boxShadow: !props.secondState ? 'unset' : '0 6px 20px rgba(0,0,0,.2)'}}
                                          >
                                            <a href={window.location.origin + property.sourcePath} target="_blank"
                                               rel="noreferrer"
                                               className={!props.secondState ? 'invisible' : 'InfoWindow-Properties__Thumb'}>

                                              <Gallery
                                                  height={210}
                                                  borderBottom={false}
                                                  property_id={property.property_id}
                                                  images={property.featured_images}
                                                  setInfoWindowState={props.setInfoWindowState}
                                              />
                                              <PanelBody
                                                  property_id={property.property_id}
                                                  property_title={property.property_title}
                                                  property_moto={property.moto}
                                                  property_location={property.location}
                                                  minimumPrice={property.minimumPrice}
                                                  bedrooms={property.bedrooms}
                                                  capacity={property.capacity}
                                                  areaSqm={property.areaSqm}
                                                  bathrooms={property.bathrooms}
                                                  estatePrice={property.estatePrice2}
                                                  isEstate={isEstate}
                                              />
                                            </a>
                                          </div>
                                        </InfoWindow>
                                    }
                                  </CustomMarker>
                                </>
                            )
                          }
                        }
                      })
                  }
                </>

          }
        </GoogleMap>
      </Fragment>
  )
}
