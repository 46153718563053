import { PropsWithChildren, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { createOverlay } from './OverlayNext'

type OverlayProps = PropsWithChildren<{
    position: google.maps.LatLng | google.maps.LatLngLiteral
    pane?: keyof google.maps.MapPanes
    map: google.maps.Map
    offsetX?: number | undefined
    offsetY?: number | undefined
    className?: string | undefined
    zIndex?: number
}>

export default function OverlayView ({
  position,
  pane = 'floatPane',
  map,
  zIndex,
  offsetX,
  offsetY,
  className,
  children,
}: OverlayProps ) {
  const container = useMemo( () => {
    const div = document.createElement( 'div' )
    div.style.position = 'absolute'
    return div
  }, [])

  const overlay = useMemo( () => {
    return createOverlay( container, pane, position, offsetX, offsetY )
  }, [ container, pane, position, offsetX, offsetY ])

  useEffect( () => {
    overlay?.setMap( map )
    return () => overlay?.setMap( null )
  }, [ map, overlay ])

  // to move the container to the foreground and background
  useEffect( () => {
    container.style.zIndex = `${zIndex}`
    if ( className )
      if ( className.split( ' ' ).length > 1 ) {
        className.split( ' ' ).map( ( thisClass ) => container.classList.add( thisClass ) )
      } else {
        if ( className.length > 0 )
          container.classList.add( className )
      }

  }, [ zIndex, container, className ])

  return createPortal( children, container )
}
