import React, {useEffect} from 'react';
import { faBed as faBed, faUser as faUser, faShower as faShower } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkers from '../../../../helpers/class/Checkers';
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../../../helpers/MethodHelper";
import {urlUseEffectParser} from "../../../../helpers/DataFetchHelper";

export default function PanelBody ( props ) {
    const isEstate = props.hasOwnProperty('isEstate') ? props.isEstate : false

    const minmimStayData = props.hasOwnProperty('minmimStayData') ? props.minmimStayData : null

    const labelForPropertiesWithoutPrices = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'builder_properties_inquires_label')
    );

    const showMinimumStayNotice = minmimStayData!=undefined && minmimStayData.hasOwnProperty('minimumStayInvalid') ? minmimStayData.minimumStayInvalid : false

    const extraDaysForMinimumStay = minmimStayData!=null && minmimStayData.hasOwnProperty('extraDaysForMinimumStay') ? minmimStayData.extraDaysForMinimumStay : null

    const minimumStay = minmimStayData!=null && minmimStayData.hasOwnProperty('minimumStay') ? minmimStayData.minimumStay : null

    const paragraphClassName = (showMinimumStayNotice || !(props.minimumPrice && Checkers.isValidPositiveNumber(props.minimumPrice))) ? 'm-t-5' : 'm-t-15'

    const paragraphMinStayClassName = showMinimumStayNotice ? 'm-t-0' : 'm-t-20'

    const spanColorClassname = showMinimumStayNotice ? 'text-danger' : 'text-master'

    const extraNightsTextHandler = () => {
        if (showMinimumStayNotice) {
            if (extraDaysForMinimumStay>1) {
                return "add " + extraDaysForMinimumStay + " nights, "
            } else {
                return "add " + extraDaysForMinimumStay + " night, "
            }
        }

        return ''
    }

    const minimumStayTextHandler = () => {
        if (showMinimumStayNotice) {
            return 'Minimum stay of ' + minimumStay + ' nights in this property';
        }

        return ''
    }

    return (
        <>
            {
                <>
                    <div className={`Properties__body ${props?.panelBodyClass}`}>
                        <div className={`${props.bodyHrefClass ? props?.bodyHrefClass : ''}`}>
                            <p className="fw-bold text-uppercase text-truncate letter-sp-normal text-color-initial fs-12 m-0">{props.property_location}</p>
                            <h5 className="fw-bold text-color-initial m-0">{props.property_title}</h5>
                            <p className="fw-normal text-color-initial fs-16 m-0 line-clamp-1">{props.property_moto}</p>
                        </div>

                        <p className={"hint-text m-b-10 text-master fs-14 " + paragraphClassName}>
                            <>
                                {
                                    !isEstate ?
                                        <>
                                            {props.capacity}&nbsp;<FontAwesomeIcon icon={faUser} className={'m-r-5'}/> (guests)
                                        </>
                                        :
                                        <>
                                            {props.areaSqm}&nbsp;m<sup>2</sup>
                                        </>
                                }
                            </>
                            &nbsp;·&nbsp;
                            {props.bedrooms}&nbsp;<FontAwesomeIcon icon={faBed} className={'m-r-5'}/> (bedrooms)
                            <>
                                {
                                    isEstate &&
                                        <>
                                            &nbsp;·&nbsp;
                                            {props.bathrooms}&nbsp;<FontAwesomeIcon icon={faShower} className={'m-r-5'}/> (bathrooms)
                                        </>
                                }
                            </>
                        </p>


                        {
                            !isEstate ?
                                <>
                                    {

                                        props.availabilityStatus != undefined && props.availabilityStatus == 0 ?
                                            <>
                                                <p className="text-right m-t-20">
                                                  <span className="fs-16 text-master">
                                                      Not available for the selected dates
                                                  </span>
                                                </p>
                                            </>
                                            :
                                            <>
                                                {
                                                    props.minimumPrice && Checkers.isValidPositiveNumber(props.minimumPrice) ?
                                                        <p className={"text-right " + paragraphMinStayClassName}>
                                                          <span className={"fs-16 " + spanColorClassname}>{extraNightsTextHandler()}from <span
                                                              className="bold">€{parseFloat(props.minimumPrice).toFixed(0)}</span>/night</span>
                                                            <span className="fs-12 text-danger block">
                                                                {
                                                                    minimumStayTextHandler()
                                                                }
                                                            </span>
                                                        </p>
                                                        :
                                                        <p className="text-right m-t-5">
                                                            <span className={"fs-16 text-master"}>
                                                                {labelForPropertiesWithoutPrices}
                                                            </span>
                                                            <span className="fs-12 text-master block">
                                                                You may submit an inquiry
                                                            </span>
                                                        </p>
                                                }
                                            </>
                                    }
                                </>
                                :
                                <>
                                {
                                    props.estatePrice ?
                                        <>
                                            <p className="text-right m-t-20">
                                                      <span className="fs-16 text-master">
                                                          € {props.estatePrice}
                                                      </span>
                                            </p>
                                        </>
                                        :
                                        <>
                                            <p className="text-right m-t-20">
                                                      <span className="fs-16 text-master">
                                                          Upon request
                                                      </span>
                                            </p>
                                        </>
                                }
                                </>
                        }
                    </div>
                </>
            }
        </>
    )
}
