import React from 'react';
import Image from 'next/image';
// import Swiper core and required modules
import SwiperCore, { Autoplay, Lazy, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Import Swiper styles
import 'swiper/css';
// install Swiper modules
SwiperCore.use([ Autoplay, Navigation, Pagination, Lazy ]);

import { faClose as farClose } from '@fortawesome/pro-regular-svg-icons';
import { faAngleLeft as fasAngleLeft, faAngleRight as fasAngleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Checkers from '../../../../helpers/class/Checkers';

export default function Gallery ( props ) {

  const myLoader = ({src}) => {
    return `${src}?q=55`
  }

  const navigation = Checkers.isValidArrayKeysNotNull(props.images) && props.images.length > 1 ? {
    nextEl: '.swiper-custom-next',
    prevEl: '.swiper-custom-prev'
  } : false;

  let validGallery = false;
  validGallery = Checkers.isValidArray(props.images) ? props.images.map((slide) => {
    if (Checkers.isValidString(slide)) return true;
  }) : false;

  return (
      <div
          key={"gallery-swiper-" +props.property_id}
          ref={props.galleryRef}
          className="Properties__gallery properties"
          onClick={!validGallery ? () => window.open('/property/' + props.property_id, '_blank') : undefined}
          style={{height: props?.galleryHeight ? props.galleryHeight : 285}}
      >
        {validGallery &&
            <Swiper
                key={"gallery-swiper-inner-" +props.property_id}
                height={300}
                speed={1000}
                navigation={navigation}
                noSwiping={Checkers.isValidBoolean(navigation)}
                pagination={{
                  dynamicBullets: true,
                  dynamicMainBullets: 3
                }}
                style={{borderRadius: "0px"}}
                slidesPerView={1}
            >
              {Checkers.objChildExists(props, 'borderBottom') && !props.borderBottom &&
                  <div className="swiper-button-close swiper-button-custom-close"
                       onMouseDown={() => Checkers.objChildExists(props, 'setInfoWindowState') ? props.setInfoWindowState(null) : undefined}
                  >
                    <FontAwesomeIcon icon={farClose} className="text-white opacity-75 fs-16"/>
                  </div>
              }
              <div
                  className={'swiper-button-prev swiper-custom-prev\ ' + `${Checkers.isValidBoolean(navigation) ? 'hidden' : ''}`}
              >
                <FontAwesomeIcon icon={fasAngleLeft} className="text-master fs-16"/>
              </div>
              <div
                  className={'swiper-button-next swiper-custom-next\ ' + `${Checkers.isValidBoolean(navigation) ? 'hidden' : ''}`}
              >
                <FontAwesomeIcon icon={fasAngleRight} className="text-master fs-16"/>
              </div>

              {props.images.map((slide, key) => {
                return (
                    Checkers.isValidString(slide) &&
                    <SwiperSlide key={key}>
                      <Image
                          loader={myLoader}
                          priority={key == 0}
                          layout={'fill'}
                          sizes={'55vw'}
                          quality={'55'}
                          src={slide}
                          alt={'Property Slide-' + props.property_id + '-' + key}
                          objectFit={'cover'}
                          placeholder={'blur'}
                          blurDataURL={'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}
                      />
                      <div className="bg-overlay z-index-1 position-absolute start-0" style={{
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0) , rgba(0, 0, 0, 0.3))',
                        height: '35%',
                        top: 'unset',
                        bottom: '15%'
                      }}/>
                      <div className="bg-overlay z-index-1 position-absolute start-0" style={{
                        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.3) , rgba(0, 0, 0, 0.8))',
                        height: '15%',
                        top: 'unset',
                        bottom: 0
                      }}/>
                    </SwiperSlide>
                )
              })}
            </Swiper>
        }
      </div>
  )
}