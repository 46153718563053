export function createOverlay (
  container: HTMLElement,
  pane: keyof google.maps.MapPanes,
  position: google.maps.LatLng | google.maps.LatLngLiteral,
  offsetX?: number | undefined,
  offsetY?: number | undefined
) {
  class OverlayNext extends google.maps.OverlayView {
    container: HTMLElement
    pane: keyof google.maps.MapPanes
    position: google.maps.LatLng | google.maps.LatLngLiteral
    offsetX?: number | undefined
    offsetY?: number | undefined
    constructor (
      container: HTMLElement,
      pane: keyof google.maps.MapPanes,
      position: google.maps.LatLng | google.maps.LatLngLiteral,
      offsetX?: number | undefined,
      offsetY?: number | undefined
    ) {
      super()
      this.container = container
      this.pane = pane
      this.position = position
      this.offsetX = offsetX
      this.offsetY = offsetY
    }

    onAdd (): void {
      const pane = this.getPanes()?.[this.pane]
      pane?.appendChild( this.container )
    }

    draw (): void {
      const projection = this.getProjection()
      const point = projection.fromLatLngToDivPixel( this.position )
      if ( point === null ) {
        return
      }

      this.container.style.transform = `translate(${point.x - this.offsetX}px, ${point.y - this.offsetY}px)`
    }

    onRemove (): void {
      if ( this.container.parentNode !== null ) {
        this.container.parentNode.removeChild( this.container )
      }
    }
  }

  return new OverlayNext( container, pane, position, offsetX, offsetY )
}