import React, {useState, useRef, useEffect} from 'react';
import { faHome as fasHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomMarker from '../GoogleMaps/CustomMarker';
import Gallery from '../templates/properties/panel/Gallery';
import PanelBody from '../templates/properties/panel/PanelBody';


import OverlayView from './OverlayView';
import {Marker, MarkerClusterer, InfoWindow, Circle} from "@react-google-maps/api";

import homePin from '../../assets/images/homePin.svg';
import {fontFamily} from "@mui/system";
import { ErrorBoundary } from "react-error-boundary";

interface MarkerWrapperProps {
    key: number
    position: any
    clusterer: any
    property: any
    infoWindowState: any
    updateInfoWindowState: any
    changeMarkerOnClick: any
    markerClass: any
    pixelOffsetX: any
    pixelOffsetY: any
    openInfoWindow: any
    map: any
    setInfoWindowState: any
    secondState: any
    randomizePin?: number
    isEstate?: boolean
}

export default function MarkerWrapper (
    {
        key,
        position,
        clusterer,
        property,
        infoWindowState,
        updateInfoWindowState,
        changeMarkerOnClick,
        markerClass,
        pixelOffsetX,
        pixelOffsetY,
        openInfoWindow,
        map,
        setInfoWindowState,
        secondState,
        randomizePin,
        isEstate
    }: MarkerWrapperProps ) {

    const isEstateView = isEstate != undefined ? isEstate : false

    return (
        <>
            <ErrorBoundary fallback={<div>Something went wrong</div>}>
                <Marker
                    key={key}
                    position={position}
                    clusterer={clusterer}
                    onClick={() => {
                        updateInfoWindowState(property.property_id);
                        changeMarkerOnClick(position.lat, position.lng, property.property_id);
                    }}
                    icon={{
                        url: ('https://loggia-cdn.s3.eu-central-1.amazonaws.com/solid_home_white-1.png'),
                        anchor: new window.google.maps.Point(
                            19,
                            19
                        ),
                        strokeWeight: 10,
                        strokeColor: "#000000",

                    }}
                >
                    {infoWindowState === property.property_id &&
                        <InfoWindow
                            zIndex={2}
                            position={position}
                            options={{
                                pixelOffset: new window.google.maps.Size(
                                    -pixelOffsetX,
                                    -pixelOffsetY
                                )
                            }}
                            onDomReady={function () {
                                const noGalleryWidthDifference = (324 - this.content.children[0].clientWidth);
                                const noGalleryHeightDifference = (310 - this.content.children[0].clientHeight);

                                const width = (this.content.children[0].clientWidth + noGalleryWidthDifference) / 2;
                                const height = this.content.children[0].clientHeight + noGalleryHeightDifference + 20 + 76;

                                if (width > 0 && height > 20) {
                                    openInfoWindow(width, height);
                                }
                                const gmEmptyElements = document.querySelectorAll('.gm-style-iw-tc');
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                for (const gmEmptyEl of gmEmptyElements) {
                                    gmEmptyEl.remove();
                                }
                            }}
                        >
                            <div
                                id={'info_window_' + property.property_id}
                                className={
                                    `${!secondState ?
                                        'bg-transparent border-radius-12 position-absolute mb-5 z-index-5'
                                        : 'bg-white border-radius-12 position-absolute mb-5 z-index-5'}`
                                }
                                style={{
                                    overflow: "hidden",
                                    boxShadow: !secondState ? 'unset' : '0 6px 20px rgba(0,0,0,.2)'
                                }}
                            >
                                <a href={window.location.origin + property.sourcePath} target="_blank"
                                   rel="noreferrer"
                                   className={!secondState ? 'invisible' : 'InfoWindow-Properties__Thumb'}>

                                    <Gallery
                                        height={210}
                                        borderBottom={false}
                                        property_id={property.property_id}
                                        images={property.featured_images}
                                        setInfoWindowState={setInfoWindowState}
                                    />
                                    <PanelBody
                                        property_id={property.property_id}
                                        property_title={property.property_title}
                                        property_moto={property.moto}
                                        property_location={property.location}
                                        minimumPrice={property.minimumPrice}
                                        bedrooms={property.bedrooms}
                                        capacity={property.capacity}
                                        isEstate={isEstateView}
                                    />
                                </a>
                            </div>
                        </InfoWindow>
                    }
                </Marker>
            </ErrorBoundary>
        </>
    )
}