import React, {useEffect} from 'react';
import { faHome as fasHome } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import OverlayView from './OverlayView';
import {Marker, MarkerClusterer} from "@react-google-maps/api";
import {useSelector} from "react-redux";
import {fetchBuilderSettingByKey} from "../../helpers/MethodHelper";
import Checkers from "../../helpers/class/Checkers";

interface CustomMarkerProps {
    marker_id: string;
    propertyMarkerClass: string;
    position: any;
    property?: any | undefined;
    map?: google.maps.Map;
    offsetX?: number | undefined;
    offsetY?: number | undefined;
    changeMarker?: any | undefined;
    openInfoWindow?: any | undefined;
    updateInfoWindowState?: any | undefined;
    onClick?: any | undefined;
    disabled?: boolean | undefined;
    children?: any | undefined;
}

export default function CustomMarker ({
  marker_id, propertyMarkerClass, position, map, offsetX, offsetY, children, ...props
}: CustomMarkerProps ) {



    const rgbDesignColor = useSelector(
        state => fetchBuilderSettingByKey(state['builderSettingsData'], 'designColor')
    );

    // useEffect( () => {
    //     console.log("rgbDesignColor", rgbDesignColor)
    // })

  return (
    <>
      {map && (
        <OverlayView
          map={map}
          position={position}
          className="d-flex flex-column-reverse justify-content-start align-items-center"
          offsetX={offsetX}
          offsetY={offsetY}
        >
          <button
              id={marker_id}
              className={propertyMarkerClass} // + ' bg-design-color'
              onClick={props.onClick}
              disabled={props.disabled}
              style={{border: "2px solid " + (Checkers.isHexColor(rgbDesignColor) ? rgbDesignColor : "#333333")}}
          >
            <FontAwesomeIcon className="fs-16" icon={fasHome} style={{ height: 16, width: 16}}/>
              {/*// text-white*/}
          </button>
          {children}
        </OverlayView>
      )}
    </>
  )

  return (
    <>
      {/*{map && (*/}
      {/*    <Marker*/}
      {/*        // map={map}*/}
      {/*        position={position}*/}
      {/*        // className="d-flex flex-column-reverse justify-content-start align-items-center"*/}
      {/*        // offsetX={offsetX}*/}
      {/*        // offsetY={offsetY}*/}
      {/*    >*/}
      {/*        /!*<button id={marker_id}*!/*/}
      {/*        /!*  className={propertyMarkerClass}*!/*/}
      {/*        /!*  onClick={props.onClick}*!/*/}
      {/*        /!*  disabled={props.disabled}*!/*/}
      {/*        /!*>*!/*/}
      {/*        /!*  <FontAwesomeIcon className="fs-16" icon={fasHome} style={{ height: 16, width: 16 }}/>*!/*/}
      {/*        /!*</button>*!/*/}
      {/*        /!*{children}*!/*/}
      {/*    </Marker>*/}
      {/*)}*/}
    </>
  )
}